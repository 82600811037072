<template>
    <div>
        <div class="form-box">
            <el-form
                ref="form"
                style="flex:1;margin-top:20px;margin-left: 40px;margin-bottom:80px"
                label-position="left"
                :model="form"
                label-width="100px"
            >
                <el-form-item label="营业执照">
                    <div v-if="businessLicense.length > 0">
                        <el-image
                            v-if="
                                form.businessLicenseUrl.indexOf('.jpg') != -1 ||
                                    form.businessLicenseUrl.indexOf('.png') !=
                                        -1 ||
                                    form.businessLicenseUrl.indexOf('.gif') !=
                                        -1
                            "
                            style="width: 100px; height: 100px"
                            :src="form.businessLicenseUrl"
                            :preview-src-list="[businessLicenseUrl]"
                        >
                        </el-image>
                        <span
                            class="pdf"
                            v-if="
                                form.businessLicenseUrl.indexOf('.pdf') != -1 ||
                                    form.businessLicenseUrl.indexOf('.PDF') !=
                                        -1
                            "
                            @click="onPreview(form.businessLicenseUrl)"
                            style="cursor: pointer;"
                        >
                            <img src="@/img/pdfUrl.png" alt=""
                        /></span>
                        <span
                            class="word"
                            v-if="form.businessLicenseUrl.indexOf('.doc') != -1"
                            @click="onPreview(form.businessLicenseUrl)"
                            style="cursor: pointer;"
                        >
                            <img src="@/img/word.png" alt="" />
                        </span>
                    </div>
                    <el-upload
                        :on-progress="progress"
                        v-if="businessLicense.length == 0"
                        :action="action"
                        class="avatar-uploader"
                        list-type="picture-card"
                        :on-success="onBusinessLicense"
                        :before-upload="beforeAvatarUploads"
                        multiple
                        drag
                        :limit="1"
                        ref="businessLicense"
                        accept=".jpg, .png, .gif, .pdf,.doc,.docx"
                        :file-list="businessLicense"
                    >
                        <i slot="default" class="el-icon-plus"></i>
                        <div
                            slot="file"
                            slot-scope="{ file }"
                            style="text-align:center"
                        >
                            <img
                                class="el-upload-list__item-thumbnail"
                                :src="form.businessLicenseUrl"
                                alt=""
                                v-if="
                                    form.businessLicenseUrl.indexOf('.jpg') !=
                                        -1 ||
                                        form.businessLicenseUrl.indexOf(
                                            '.png'
                                        ) != -1 ||
                                        form.businessLicenseUrl.indexOf(
                                            '.gif'
                                        ) != -1
                                "
                            />
                            <span
                                v-if="
                                    form.businessLicenseUrl.indexOf('.pdf') !=
                                        -1 ||
                                        form.businessLicenseUrl.indexOf(
                                            '.PDF'
                                        ) != -1
                                "
                            >
                                <img src="@/img/pdfUrl.png" alt="" />
                            </span>
                            <span
                                v-if="
                                    form.businessLicenseUrl.indexOf('.doc') !=
                                        -1
                                "
                            >
                                <img src="@/img/word.png" alt="" />
                            </span>
                            <span class="el-upload-list__item-actions">
                                <span
                                    class="el-upload-list__item-preview"
                                    @click="handlePictureCardPreview(file)"
                                    v-if="
                                        form.businessLicenseUrl.indexOf(
                                            '.jpg'
                                        ) != -1 ||
                                            form.businessLicenseUrl.indexOf(
                                                '.png'
                                            ) != -1 ||
                                            form.businessLicenseUrl.indexOf(
                                                '.gif'
                                            ) != -1
                                    "
                                >
                                    <i class="el-icon-zoom-in"></i>
                                </span>
                                <span
                                    v-if="!disabled"
                                    class="el-upload-list__item-delete"
                                    @click="handleDownload(file)"
                                >
                                    <i class="el-icon-download"></i>
                                </span>
                                <span
                                    v-if="!disabled"
                                    class="el-upload-list__item-delete"
                                    @click="handleRemove(file)"
                                >
                                    <i class="el-icon-delete"></i>
                                </span>
                            </span>
                        </div>
                    </el-upload>
                </el-form-item>

                <el-form-item label="附件" required style="margin-left:-10px">
                    <div
                        v-for="item in contractAttachmentList"
                        class="contractAttachmentList"
                    >
                        <el-image
                            v-if="
                                item.attachmentUrl.indexOf('.jpg') != -1 ||
                                    item.attachmentUrl.indexOf('.png') != -1 ||
                                    item.attachmentUrl.indexOf('.gif') != -1
                            "
                            style="width: 100px; height: 100px"
                            :src="item.attachmentUrl"
                            :preview-src-list="[item.attachmentUrl]"
                        >
                        </el-image>
                        <span
                            class="pdf"
                            v-if="
                                item.attachmentUrl.indexOf('.pdf') != -1 ||
                                    item.attachmentUrl.indexOf('.PDF') != -1
                            "
                            @click="onPreview(item.attachmentUrl)"
                            style="cursor: pointer;"
                        >
                            <img src="@/img/pdfUrl.png" alt="" />
                        </span>
                        <span
                            class="word"
                            v-if="item.attachmentUrl.indexOf('.doc') != -1"
                            @click="onPreview(item.attachmentUrl)"
                            style="cursor: pointer;"
                        >
                            <img src="@/img/word.png" alt="" />
                        </span>
                    </div>
                    <el-upload
                        drag
                        :on-progress="progress"
                        style="margin-left:10px"
                        :action="action"
                        class="avatar-uploader"
                        list-type="picture-card"
                        :on-success="onAttachment"
                        :before-upload="beforeAvatarUploads"
                        multiple
                        :file-list="fileList"
                        :limit="limit"
                        ref="attachment"
                        accept=".jpg, .png, .gif, .pdf,.doc,.docx"
                    >
                        <i slot="default" class="el-icon-plus"></i>
                        <div
                            slot="file"
                            slot-scope="{ file }"
                            style="text-align:center"
                        >
                            <img
                                class="el-upload-list__item-thumbnail"
                                :src="file.url"
                                v-if="
                                    file.url &&
                                        (file.url.indexOf('.jpg') != -1 ||
                                            file.url.indexOf('.png') != -1 ||
                                            file.url.indexOf('.gif') != -1)
                                "
                            />
                            <span
                                v-if="
                                    file.url &&
                                        (file.url.indexOf('.pdf') != -1 ||
                                            file.url.indexOf('.PDF') != -1)
                                "
                            >
                                <img src="@/img/pdfUrl.png" alt="" />
                            </span>
                            <span
                                v-if="
                                    file.url && file.url.indexOf('.doc') != -1
                                "
                            >
                                <img src="@/img/word.png" alt="" />
                            </span>
                            <span class="el-upload-list__item-actions">
                                <span
                                    class="el-upload-list__item-preview"
                                    @click="handlePictureCardPreview(file)"
                                    v-if="
                                        file.url &&
                                            (file.url.indexOf('.jpg') != -1 ||
                                                file.url.indexOf('.png') !=
                                                    -1 ||
                                                file.url.indexOf('.gif') != -1)
                                    "
                                >
                                    <i class="el-icon-zoom-in"></i>
                                </span>
                                <span
                                    v-if="!disabled"
                                    class="el-upload-list__item-delete"
                                    @click="handleDownload(file)"
                                >
                                    <i class="el-icon-download"></i>
                                </span>
                                <span
                                    v-if="!disabled"
                                    class="el-upload-list__item-delete"
                                    @click="handleRemoves(file)"
                                >
                                    <i class="el-icon-delete"></i>
                                </span>
                            </span>
                        </div>
                    </el-upload>
                </el-form-item>
            </el-form>
        </div>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background:none;border-color:#D5D6DF;color:#666666;"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                :loading="loading"
                type="primary"
                style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:32px"
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
        <el-dialog :visible.sync="dialogVisible" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
    </div>
</template>
<script>
import { addAttachment } from '@/api/user/ae/contract.js';
export default {
    data() {
        return {
            form: {},
            action: this.$url.url() + '/file/local/upload/contract',
            fileList: [],
            disabled: false,
            dialogVisible: false,
            dialogImageUrl: '',
            businessLicense: [],
            id: '',
            limit: 5,
            contractAttachmentList: [],
            loading: false,
        };
    },
    methods: {
        getData(row) {
            this.fileList = [];
            this.fileArr = [];
            this.id = row.id;
            this.form.businessLicenseUrl = row.businessLicenseUrl;
            this.businessLicense = [];
            if (row.businessLicenseUrl) {
                this.businessLicense = [{ url: row.businessLicenseUrl }];
            }
            this.contractAttachmentList = row.contractAttachmentList;
            this.limit = this.limit - row.contractAttachmentList.length;
        },
        progress() {
            this.loading = true;
        },
        // 上传营业执照
        onBusinessLicense(response) {
            this.loading = false;
            if ((response.code = 200)) {
                this.$message.success('营业执照上传成功');
                this.form.businessLicenseUrl = response.data;
            } else {
                this.$message.success('营业执照上传失败');
            }
        },
        // 上传附件
        onAttachment(response) {
            this.fileArr.push({ url: response.data });
            setTimeout(() => {
                this.fileList = this.fileArr;
                this.loading = false;
            }, 2000);
            // this.loading = false
            // if ((response.code = 200)) {
            //     this.$message.success('附件上传成功');
            //     this.fileList.push({ url: response.data });
            // } else {
            //     this.$message.success('附件上传失败');
            // }
        },
        beforeAvatarUploads(file) {
            const isLt2M = file.size / 1024 / 1024 < 20;
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 20MB!');
            }
            return isLt2M;
        },
        // 预览图片
        handlePictureCardPreview(file) {
            // console.log(file);
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        // 下载
        handleDownload(file) {
            // console.log(file.url);
            if (
                file.url.indexOf('.doc') != -1 ||
                file.url.indexOf('.docx') != -1
            ) {
                window.open(
                    'https://view.officeapps.live.com/op/view.aspx?src=' +
                        file.url
                );
            } else {
                window.open(file.url, '_blank');
            }
        },
        // 营业执照删除
        handleRemove(file) {
            this.$refs['businessLicense'].uploadFiles = [];
            this.form.businessLicenseUrl = '';
        },
        // 附件删除
        handleRemoves(file) {
            for (let i in this.fileList) {
                if (this.fileList[i].url == file.url) {
                    this.fileArr.splice(i, 1);
                }
            }

            this.fileList = this.fileArr;
        },
        onPreview(url) {
            if (url.indexOf('.doc') || url.indexOf('.docx')) {
                window.open(
                    'https://view.officeapps.live.com/op/view.aspx?src=' +
                        url +
                        '/' +
                        file
                );
            } else {
                window.open(url, '_blank');
            }
        },
        onSubmit() {
            let data = {
                param: {
                    id: this.id,
                    businessLicenseUrl: this.form.businessLicenseUrl,
                    contractAttachmentList: [],
                },
            };
            for (let i in this.fileList) {
                data.param.contractAttachmentList.push({
                    attachmentUrl: this.fileList[i].url,
                });
            }
            for (let i in this.contractAttachmentList) {
                data.param.contractAttachmentList.push({
                    attachmentUrl: this.contractAttachmentList[i].attachmentUrl,
                });
            }
            addAttachment(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('添加成功');
                }
                this.onClose();
            });
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.form-box {
    max-height: 600px;
    overflow: auto;
    /deep/ .avatar-uploader .el-upload {
        border: none;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        background: #f3f6fb;
        width: 120px;
        height: 120px;
        line-height: 120px;
        text-align: center;
    }
    /deep/.el-upload-dragger {
        width: 120px;
        height: 120px;
        line-height: 120px;
        text-align: center;
    }
    /deep/ .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    /deep/.avatar-uploader-icon {
        color: #8c939d;
        width: 120px;
        height: 120px;
        line-height: 80px;
        text-align: center;
    }
    /deep/ .el-upload-list__item.is-success {
        width: 120px;
        height: 120px;
    }

    .contractAttachmentList {
        display: inline-block;
        margin-right: 20px;
        margin-left: 10px;
    }
}
.bottom-but {
    width: 100%;
    line-height: 48px;
    background: #fff;
    border-top: 1px solid #dee0e3;
    margin-top: 16px;
    text-align: right;
    button {
        width: 90px;
    }
}
</style>

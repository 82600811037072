import http from '@/utils/http.js'

// 所有的合同列表   
export function contractAll(data) {
    return http({
        url: "/jasoboss/contract/list",
        method: "post",
        data
    })
}
// 获取订单列表
export function orderAll(data) {
    return http({
        url: "/jasoboss/order/list/contract/id",
        method: "post",
        data
    })
}
// 添加订单批量
export function orderAdd(data) {
    return http({
        url: "/jasoboss/order/add",
        method: "post",
        data
    })
}
// 添加订单批量
export function orderTitle(data) {
    return http({
        url: "/jasoboss/contract/list/statistics",
        method: "post",
        data
    })
}
// 根据合同ID获取合同订单列表
export function orderList(data) {
    return http({
        url: "/jasoboss/contract/order/list",
        method: "post",
        data
    })
}
// 腾讯
export function tmReportList(data) {
    return http({
        url: "/jasoboss/customer/tencent/report/list",
        method: "post",
        data
    })
}
// 阿里
export function aliReportList(data) {
    return http({
        url: "/jasoboss/customer/ali/report/list",
        method: "post",
        data
    })
}
// 所有订单列表
export function orderListAll(data) {
    return http({
        url: "/jasoboss/order/list",
        method: "post",
        data
    })
}
// Boss额度
export function bossQuota(data) {
    return http({
        url: "/jasoboss/order/tencent/email",
        method: "post",
        data
    })
}

// 根据客户id获取合同列表
export function contractCus(data) {
    return http({
        url: "/jasoboss/contract/list/customer/id",
        method: "post",
        data
    })
}
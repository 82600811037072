import http from '@/utils/http.js'

// 线索列表
export function clueList(data) {
    return http({
        url: "/jasoboss/clue/line/list",
        method: "post",
        data
    })
}

// 公海线索列表
export function clueHighList(data) {
    return http({
        url: "/jasoboss/clue/high/list",
        method: "post",
        data
    })
}

// 线索添加
export function addClue(data) {
    return http({
        url: "/jasoboss/clue/line/add",
        method: "post",
        data
    })
}

// // 查询部门成员
export function roleList(data) {
    return http({
        url: "/jasoboss/admin/role/type/list",
        method: "post",
        data
    })
}

// 编辑线索
export function editClue(data) {
    return http({
        url: "/jasoboss/clue/line/update",
        method: "post",
        data
    })
}
// 线索代办添加
export function addTodo(data) {
    return http({
        url: "/jasoboss/clue/line/todo/add",
        method: "post",
        data
    })
}
// 线索待办删除
export function delTodo(data) {
    return http({
        url: "/jasoboss/clue/line/todo/delete",
        method: "post",
        data
    })
}
// 线索代办列表
export function todoList(data) {
    return http({
        url: "/jasoboss/clue/line/todo/list",
        method: "post",
        data
    })
}

// 线索添加往来
export function addClueDealing(data) {
    return http({
        url: "/jasoboss/clue/line/intercourse/add",
        method: "post",
        data
    })
}
// 线索添加协作人
export function addClueAssist(data) {
    return http({
        url: "/jasoboss/clue/line/add/synergy",
        method: "post",
        data
    })
}
// 线索客户
export function relationCustomer(data) {
    return http({
        url: "/jasoboss/customer/line/relation",
        method: "post",
        data
    })
}
// 领取并转为客户，根据线索的联系手机号查询未完成的客户列表
export function clueContact(data) {
    return http({
        url: "/jasoboss/customer/list/clue/contact",
        method: "post",
        data
    })
}

// 线索和客户一起添加
export function addClueCustomer(data) {
    return http({
        url: "/jasoboss/clue/line/customer/add",
        method: "post",
        data
    })
}
// 市场线索共享
export function clueShare(data) {
    return http({
        url: "/jasoboss/clue/line/add/share",
        method: "post",
        data
    })
}

// 获取单个线索
export function oneClue(data) {
    return http({
        url: "/jasoboss/clue/line/get",
        method: "post",
        data
    })
}




// 意向客户线索列表
export function oldClueList(data) {
    return http({
        url: "/jasoboss/clue/list",
        method: "post",
        data
    })
}

// 修改线索状态
export function updateClue(data) {
    return http({
        url: "/jasoboss/clue/update",
        method: "post",
        data
    })
}

// 修改代办
export function editTodo(data) {
    return http({
        url: "/jasoboss/clue/line/todo/update",
        method: "post",
        data
    })
}